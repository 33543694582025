import React from "react";

const Portfolio = () => {
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <iframe
        src="/Resume.html"
        title="Resume"
        width="100%"
        height="100%"
        style={{ border: "none" }}
      />
    </div>
  );
};

export default Portfolio;
